import { DocumentParty, FeedbackResult } from "@atlas-ui/types";

import { AxiosError } from "axios";
import { omit } from "lodash";

import API from "./api";

interface CreateKeyValuePairPayload {
  name: string;
  value: string;
  sectionId?: string;
  isFavorite?: boolean;
  hidden?: boolean;
}

interface UpdateKeyValuePairPayload extends Partial<CreateKeyValuePairPayload> {
  id: string;
  feedback?: FeedbackResult;
  sectionId?: string;
}

export interface GetAllPartiesParams {
  documentTypeId?: string;
  insights?: boolean;
}

export interface GetAllKvpValuesParams {
  documentTypeId?: string;
  insights?: boolean;
  kvpCode: string;
}

export const createKeyValuePair = async (
  documentId: string,
  { name, value, isFavorite, sectionId }: CreateKeyValuePairPayload
) => {
  try {
    const response = await API.post(`document-kvps/${documentId}`, {
      name,
      value,
      sectionId,
      isFavorite,
    });

    return response.data;
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error creating key information"
    );
  }
};

export const deleteKeyValuePair = async (documentId: string, kvpId: string) => {
  try {
    const response = await API.delete(`document-kvps/${documentId}/${kvpId}`);

    return response.data;
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error deleting key information"
    );
  }
};

export const patchKeyValuePair = async (
  documentId: string,
  data: UpdateKeyValuePairPayload
) => {
  try {
    const response = await API.patch(`document-kvps/${documentId}/${data.id}`, {
      ...omit(data, "id"),
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.error);
    }

    throw new Error(
      (error as Error)?.message || "Error patching key information"
    );
  }
};

export const getAllKvpValues = async ({
  kvpCode,
  documentTypeId,
  insights,
}: GetAllKvpValuesParams) => {
  try {
    const { data } = await API.get(
      `kvps/:kvpCode/values`.replace(":kvpCode", kvpCode),
      {
        params: { documentTypeId, insights },
      }
    );

    return data;
  } catch (err) {
    throw new Error(
      (err as Error)?.message || "Error fetching key information"
    );
  }
};

export const getAllParties = async ({
  documentTypeId,
  insights,
}: GetAllPartiesParams): Promise<DocumentParty[]> => {
  try {
    return await getAllKvpValues({
      kvpCode: "902",
      documentTypeId,
      insights,
    });
  } catch (err) {
    throw new Error((err as Error)?.message || "Error fetching parties");
  }
};
