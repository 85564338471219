/* eslint-disable @typescript-eslint/no-explicit-any */

import { getAccessToken, withApiAuthRequired } from "@auth0/nextjs-auth0";

import { HTTP_METHOD } from "next/dist/server/web/http";
import { NextRequest, NextResponse } from "next/server";

import API from "./api";

const methodsWithBody = ["POST", "PUT", "PATCH"];

const getRequestParams = async (
  method: HTTP_METHOD,
  req: NextRequest,
  res: NextResponse
) => {
  const { accessToken } = await getAccessToken(req, res);

  const paramsIterator = req.nextUrl.searchParams.entries();
  const params = Array.from(paramsIterator).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as any);

  return {
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Proxy": "true",
    },
    ...(methodsWithBody.includes(method) && { data: await req.json() }),
  };
};

export const GET = withApiAuthRequired(async (req, ctx) => {
  const route = (ctx.params?.["route"] as string[]).join("/");

  try {
    const res = new NextResponse();

    const { data } = await API.get(
      route,
      await getRequestParams(req.method as HTTP_METHOD, req, res)
    );

    return NextResponse.json(data, res);
  } catch (error: any) {
    return NextResponse.json(
      {
        error: error.response.data.message,
        data: error?.response?.data,
      },
      {
        status: error.response.data.statusCode,
      }
    );
  }
});

export const POST = withApiAuthRequired(async (req, ctx) => {
  const route = (ctx.params?.["route"] as string[]).join("/");

  try {
    const res = new NextResponse();
    const { data: body, ...config } = await getRequestParams(
      req.method as HTTP_METHOD,
      req,
      res
    );

    const { data } = await API.post(route, body, config);

    return NextResponse.json(data, res);
  } catch (error: any) {
    return NextResponse.json(
      {
        error: error.response.data.message,
        data: error?.response?.data,
      },
      {
        status: error.response.data.statusCode,
      }
    );
  }
});

export const PUT = withApiAuthRequired(async (req, ctx) => {
  const route = (ctx.params?.["route"] as string[]).join("/");

  try {
    const res = new NextResponse();
    const { data: body, ...config } = await getRequestParams(
      req.method as HTTP_METHOD,
      req,
      res
    );

    const { data } = await API.put(route, body, config);

    return NextResponse.json(data, res);
  } catch (error: any) {
    return NextResponse.json(
      {
        error: error.response.data.message,
        data: error?.response?.data,
      },
      {
        status: error.response.data.statusCode,
      }
    );
  }
});

export const PATCH = withApiAuthRequired(async (req, ctx) => {
  const route = (ctx.params?.["route"] as string[]).join("/");

  try {
    const res = new NextResponse();
    const { data: body, ...config } = await getRequestParams(
      req.method as HTTP_METHOD,
      req,
      res
    );

    const { data } = await API.patch(route, body, config);

    return NextResponse.json(data, res);
  } catch (error: any) {
    return NextResponse.json(
      {
        error: error?.response?.data.message,
        data: error?.response?.data,
      },
      {
        status: error?.response?.data.statusCode,
      }
    );
  }
});

export const DELETE = withApiAuthRequired(async (req, ctx) => {
  const route = (ctx.params?.["route"] as string[]).join("/");

  try {
    const res = new NextResponse();
    const config = await getRequestParams(req.method as HTTP_METHOD, req, res);

    const { data } = await API.delete(route, config);

    return NextResponse.json(data, res);
  } catch (error: any) {
    return NextResponse.json(
      {
        error: error?.response?.data.message,
        data: error?.response?.data,
      },
      {
        status: error?.response?.data.statusCode ?? 400,
      }
    );
  }
});
