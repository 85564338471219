import { Document, DocumentKeyValuePair, DocumentStatus } from "./document";
import { KeyValuePair } from "./key-value-pairs";

export enum InsightStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface Insight {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: InsightStatus;
  documents?: Document[];
  results?: InsightResult[];
  documentTypeId: string;
}

export interface InsightResult {
  id: string;
  result: string;
  status: DocumentStatus;
  keyValuePair: Pick<KeyValuePair, "name" | "insightsDefinition">;
  documentKvps?: DocumentKeyValuePair[];
}
