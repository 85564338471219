/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessages, User, UserPatch } from "@atlas-ui/types";

import API from "./api";

export const getUser = async (): Promise<User> => {
  try {
    const response = await API.get(`/users/me`);

    const data = response.data;

    return data as User;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting user");
  }
};

export const patchUser = async (user: UserPatch): Promise<User> => {
  try {
    const response = await API.patch(`/users/me`, user);

    const data = response.data;

    return data as User;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error patching user");
  }
};

export const inviteUser = async (email: string) => {
  try {
    const response = await API.post(`/users/invite`, { email });

    return response.data;
  } catch (error) {
    const responseData = (error as any)?.response?.data.data;
    if (responseData) {
      const { errorCode } = responseData;
      if (errorCode && ErrorMessages[errorCode]) {
        throw {
          ...ErrorMessages[errorCode],
          message: ErrorMessages[errorCode].message.replace("{email}", email),
        };
      }
      throw responseData;
    }
    throw new Error((error as Error)?.message || "Error inviting user");
  }
};

export const removeUser = async (userId: string) => {
  try {
    await API.delete(`/users/${userId}`);
  } catch (error) {
    throw new Error((error as Error)?.message || "Error removing user");
  }
};
