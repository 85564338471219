import { useQuery } from "react-query";

import { getOrganizationLimits } from "@atlas-ui/services";

import { Entity, EventData, EventType } from "../realtime/realtime-context";
import { useEvent } from "../realtime/use-event";
import { useDocsnapUser } from "./use-docsnap-user";

export const useDocsnapLimits = () => {
  const { data: user } = useDocsnapUser();

  const organizationId = user?.organizations?.[0]?.id;

  const query = useQuery({
    queryKey: ["organization-limits", organizationId],
    queryFn: () => getOrganizationLimits(organizationId),
    enabled: !!organizationId,
    onError(err) {
      console.error(err);
    },
  });

  const handleWebsocketEvent = async (type: EventType, data: EventData) => {
    if (type === EventType.UPDATE && data.id === organizationId) {
      await query.refetch();
    }
  };

  useEvent({
    entity: Entity.USAGE,
    events: [EventType.UPDATE],
    handler: handleWebsocketEvent,
  });

  return query;
};
