import { useContext, useEffect } from "react";

import { Entity, EventData, EventType, RealtimeContext, SocketEvent } from "..";

interface UseEventProps {
  entity: Entity;
  events: EventType[];
  handler: (type: EventType, data: EventData) => void | Promise<void>;
}

export const useEvent = ({ entity, events, handler }: UseEventProps) => {
  const { eventsSubject, isConnected } = useContext(RealtimeContext);

  useEffect(() => {
    if (!eventsSubject || !isConnected) {
      return;
    }

    const observer = eventsSubject?.subscribe(
      ({ data, event, entity: eventEntity }: SocketEvent) => {
        if (eventEntity === entity && events.includes(event)) {
          handler(event, data);
        }
      }
    );

    return () => {
      observer?.unsubscribe();
    };
  }, [entity, events, eventsSubject, handler, isConnected]);
};
